import CourseCard from "../components/cards/CourseCard";
import data from "../config/data.json";
import "../assets/styles/courses.css";
import { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";

const AllCourses = (props) => {
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("All");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/subject");
        console.log("RESPONSE: ", response);
        const courseData = response.data;

        // Map categories
        const categoryMap = courseData.reduce((acc, course) => {
          acc[course.category] = (acc[course.category] || 0) + 1;
          return acc;
        }, {});

        const categoryList = [
          { title: "All", number: courseData.length },
          ...Object.keys(categoryMap).map((category) => ({
            title: category,
            number: categoryMap[category],
          })),
        ];

        setCategories(categoryList);
        setCourses(courseData);
      } catch (err) {
        console.error("Error fetching courses:", err);
        setError("Failed to load courses. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const filteredCourses =
    currentCategory === "All"
      ? courses
      : courses.filter((course) => course.category === currentCategory);

  const setCategory = (category) => {
    setCurrentCategory(category);
  };

  // useEffect(() => {
  //   let map = {};
  //   for (let course of data.COURSES) {
  //     console.log();
  //     const category = course.category;
  //     if (!(category in map)) map[category] = 0;
  //     map[category]++;
  //   }

  //   const cats = [
  //     {
  //       title: "All",
  //       number: data.COURSES.length,
  //     },
  //   ];

  //   for (let cat in map) {
  //     const category = {
  //       title: cat,
  //       number: map[cat],
  //     };

  //     cats.push(category);
  //   }

  //   setCategories(cats);
  // }, []);

  // useEffect(() => {
  //   let temp;
  //   switch (currentCategory) {
  //     case "All":
  //       temp = data.COURSES;

  //       break;
  //     case "ISO Program":
  //       temp = data.COURSES.filter((c) => c.category === "ISO Program");

  //       break;
  //     case "Cyber Security Program":
  //       temp = data.COURSES.filter(
  //         (c) => c.category === "Cyber Security Program"
  //       );

  //       break;
  //     case "Network Program":
  //       temp = data.COURSES.filter((c) => c.category === "Network Program");
  //       break;
  //     case "VMware Program":
  //       temp = data.COURSES.filter((c) => c.category === "VMware Program");
  //       break;
  //     case "Fortinet Program":
  //       temp = data.COURSES.filter((c) => c.category === "Fortinet Program");
  //       break;
  //     case "Zscaler Program":
  //       temp = data.COURSES.filter((c) => c.category === "Zscaler Program");
  //       break;
  //     case "Scrum Program":
  //       temp = data.COURSES.filter((c) => c.category === "Scrum Program");
  //       break;
  //     case "Juniper Program":
  //       temp = data.COURSES.filter((c) => c.category === "Juniper Program");
  //       break;
  //     case "ITIL Program":
  //       temp = data.COURSES.filter((c) => c.category === "ITIL Program");
  //       break;
  //   }

  //   setCourses(temp);
  // }, [currentCategory]);

  // const setCategory = (category) => {
  //   setCurrentCategory(category);
  // };

  // if (categories.length === 0 || courses.length === 0) return <p>loading...</p>;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div id="content" className="site-content" bis_skin_checked="1">
      <div className="lp-archive-courses" bis_skin_checked="1">
        <div className="lp-content-area" bis_skin_checked="1">
          <div className="row mt-4">
            <div className="col-lg-3 mt-5">
              <div className="blog_sidebar left-sidebar">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  className="single_sidebar"
                >
                  <h3>Categories</h3>
                  <div className="category_list">
                    {categories.map((cat, idx) => {
                      return (
                        <p key={idx}>
                          <a
                            className={
                              currentCategory === cat.title ? "active" : ""
                            }
                            onClick={setCategory.bind(this, cat.title)}
                          >
                            <i className="arrow_carrot-right"></i>
                            {cat.title} ({cat.number})
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <header className="learn-press-courses-header mb-4">
                <h1>
                  {currentCategory === "All" ? "All Prorgams" : currentCategory}
                </h1>
              </header>

              <ul className="learn-press-courses" data-layout="list">
                {filteredCourses.map((course, idx) => {
                  return (
                    <CourseCard
                      course={course}
                      key={idx}
                      onSetCategory={setCategory}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCourses;
