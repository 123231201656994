import "./navbar.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AdminAuthContext";

const Navbar = () => {
  //   const { dispatch } = useContext(DarkModeContext);
  const { user } = useContext(AuthContext);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              //   onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            {/* <div className="counter">1</div> */}
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            {/* <div className="counter">2</div> */}
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          {user && (
            <div className="item user-section">
              <img
                src={
                  user.avatar
                    ? `${process.env.REACT_APP_PUBLIC_URL}${user.avatar}`
                    : "https://via.placeholder.com/40"
                }
                alt={`${user.firstname}'s avatar`}
                className="avatar"
              />
              <span>Hi, {user.firstname}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
