// src/pages/admin/users/UserDetail.jsx
import React from "react";
import Single from "../../single/Single";
import { userDetailFields } from "../../../../datadetails";

const UserDetails = () => {
  return (
    <Single
      entity="User"
      apiEndpoint="/user"
      chartTitle="User Spending (Last 6 Months)"
      fields={userDetailFields}
      hasChart={true}
      avatarKey="avatar"
      hasEdit={false}
    />
  );
};

export default UserDetails;
