// NewCategory.js
import New from "../../new/New";
import { subjectInputs } from "../../../../formSource";
import axiosInstance from "../../../../utils/axiosInstance";
import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../components/layout/loader/Loader";

const NewSubject = () => {
  const { id } = useParams(); // Get `id` from URL
  const navigate = useNavigate();
  const isEditMode = !!id; // Determine if it's edit mode
  const [initialData, setInitialData] = useState(null);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");

  useEffect(() => {
    if (isEditMode) {
      // Fetch the category data for editing
      const fetchSubject = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get(`/subject/${id}`);
          setInitialData(response.data.data); // Assuming your API response includes `data`
        } catch (error) {
          console.error("Failed to fetch category for editing:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchSubject();
    }
  }, [id, isEditMode]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/subjectcategory");
        setCategories(
          response.data.data.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        );
        setLoading(false);
      } catch (error) {
        setFetchError("Failed to fetch categories");
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // const updatedInputs = subjectInputs.map((input) =>
  //   input.id === "categoryId"
  //     ? { ...input, type: "select", options: categories }
  //     : input
  // );

  const updatedInputs = useMemo(
    () =>
      subjectInputs.map((input) =>
        input.id === "categoryId"
          ? { ...input, type: "select", options: categories }
          : input
      ),
    [categories] // Recompute only when `categories` changes
  );

  const handleSubjectInputChange = (id, value, updatedValues) => {
    // console.log(
    //   "Input changed:",
    //   id,
    //   value,
    //   "Current form values:",
    //   updatedValues
    // );
  };

  const handleSubjectSubmit = async (formValues, file) => {
    console.log("formValues", formValues);
    const errors = {};

    // Validate subjectName
    if (!formValues.subjectName || formValues.subjectName.trim() === "") {
      errors.subjectName = "Subject name is required";
    }

    // Validate categoryId
    // if (!formValues.categoryId || formValues.categoryId.trim() === "") {
    //   errors.categoryId = "Category ID is required";
    // }
    const categoryId = formValues.categoryId?.toString().trim(); // Ensure it's a string before trimming
    if (!categoryId) {
      errors.categoryId = "Category ID is required";
    }

    // Validate creditHours
    // if (!formValues.creditHours || formValues.creditHours.trim() === "") {
    //   errors.creditHours = "Credit hours are required";
    // } else if (
    //   isNaN(formValues.creditHours) ||
    //   Number(formValues.creditHours) <= 0
    // ) {
    //   errors.creditHours = "Credit hours must be a positive number";
    // }

    const creditHours = formValues.creditHours?.toString().trim(); // Ensure it's a string
    if (!creditHours) {
      errors.creditHours = "Credit hours are required";
    } else if (isNaN(creditHours) || Number(creditHours) <= 0) {
      errors.creditHours = "Credit hours must be a positive number";
    }

    // Validate overview
    if (!formValues.overview || formValues.overview.trim() === "") {
      errors.overview = "Overview is required";
    }

    if (formValues.isPopular === undefined || formValues.isPopular === "") {
      formValues.isPopular = false; // Default to false if not set
    }

    // Validate targetAudience
    if (!formValues.targetAudience || formValues.targetAudience.trim() === "") {
      errors.targetAudience = "Target audience is required";
    }

    // Validate benefitsForOrganization
    if (
      !formValues.benefitsForOrganization ||
      formValues.benefitsForOrganization.trim() === ""
    ) {
      errors.benefitsForOrganization = "Benefits for organization are required";
    }

    // Validate howToAcquire
    if (!formValues.howToAcquire || formValues.howToAcquire.trim() === "") {
      errors.howToAcquire = "How to acquire is required";
    }

    if (Object.keys(errors).length > 0) {
      return {
        success: false,
        errors,
      };
    }

    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    try {
      if (isEditMode) {
        await axiosInstance.put(`/subject/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Subject updated successfully");
        navigate("/admin/subjects");
      } else {
        await axiosInstance.post("/subject", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Subject created successfully");
      }
      return { success: true };
    } catch (error) {
      console.error("Failed to create subject:", error);
      // Return errors as needed
      return {
        success: false,
        errors: { general: "Failed to create subject" },
      };
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader size="100px" color="#6439ff" />
        </div>
      ) : fetchError ? (
        <p>{fetchError}</p>
      ) : (
        <New
          inputs={updatedInputs}
          title={isEditMode ? "Edit Subject" : "Add New Subject"}
          onInputChange={handleSubjectInputChange}
          onSubmit={handleSubjectSubmit}
          fileKey="thumbnail"
          initialData={initialData}
          loading={loading}
        />
      )}
    </div>
  );
};

export default NewSubject;
