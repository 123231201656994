// src/router.js

import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AllCourses from "./pages/AllCourses";
import CourseDetails from "./pages/CourseDetails";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import AdminLogin from "./pages/admin/login/AdminLogin";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import Layout from "./components/layout/Layout";
import AdminLayout from "./components/admin-layout/AdminLayout";
import ProtectedRoute from "./components/ProtectedRoute";
// import New from "./pages/admin/new/New";
// import { userInputs } from "./formSource";
import UsersList from "./pages/admin/users/list/UsersList";
import SubjectCategoriesList from "./pages/admin/subject-categories/list/SubjectCategoriesList";
import SubjectsList from "./pages/admin/subjects/list/SubjectsList";
import NewSubjectCategory from "./pages/admin/subject-categories/new/NewSubjectCategory";
import NewUser from "./pages/admin/users/new/NewUser";
import NewSubject from "./pages/admin/subjects/new/NewSubject";
// import Single from "./pages/admin/single/Single";
import UserDetails from "./pages/admin/users/details/UserDetails";
import SubejctCategoryDetails from "./pages/admin/subject-categories/details/SubjectCategoryDetails";
import SubejctDetails from "./pages/admin/subjects/details/SubjectDetails";
import ProfileDetails from "./pages/admin/profile/ProfileDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "programs",
        element: <AllCourses />,
      },
      {
        path: "courses/:id",
        element: <CourseDetails />,
      },
      {
        path: "contact-us",
        element: <Contact />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
    ],
  },
  {
    path: "/admin",
    children: [
      {
        path: "login",
        element: <AdminLogin />,
      },
      {
        element: <ProtectedRoute />, // Wrap protected routes
        children: [
          {
            element: <AdminLayout />, // Layout for admin
            children: [
              {
                path: "dashboard",
                element: <Dashboard />,
              },
              {
                path: "users",
                element: <UsersList />,
              },
              {
                path: "users/new",
                element: <NewUser />,
              },
              {
                path: "users/:id",
                element: <UserDetails />,
              },
              {
                path: "subject-categories",
                element: <SubjectCategoriesList />,
              },
              {
                path: "subject-categories/new",
                element: <NewSubjectCategory />,
              },
              {
                path: "subject-categories/:id/edit",
                element: <NewSubjectCategory />,
              },
              {
                path: "subject-categories/:id",
                element: <SubejctCategoryDetails />,
              },
              {
                path: "subjects",
                element: <SubjectsList />,
              },
              {
                path: "subjects/new",
                element: <NewSubject />,
              },
              {
                path: "subjects/:id",
                element: <SubejctDetails />,
              },
              {
                path: "subjects/:id/edit",
                element: <NewSubject />,
              },
              {
                path: "profile",
                element: <ProfileDetails />,
              },
              {
                path: "profile/edit",
                element: <NewUser />,
              },
            ],
          },
        ],
      },
      {
        path: "",
        element: <Navigate to="dashboard" replace />, // Redirect /admin to /admin/dashboard
      },
    ],
  },
  {
    path: "*",
    element: <div>404 Not Found</div>,
  },
]);

export default router;
