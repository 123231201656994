// src/pages/admin/single/Single.jsx
import "./single.css";
import Sidebar from "../../../components/admin/sidebar/Sidebar";
import Navbar from "../../../components/admin/navbar/Navbar";
import Chart from "../../../components/admin/chart/Chart";
import List from "../../../components/admin/table/Table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import Loader from "../../../components/layout/loader/Loader";

const Single = ({
  entity,
  fields,
  apiEndpoint,
  chartTitle,
  hasChart = true,
  avatarKey = "avatar",
  editLink,
  hasEdit = true,
  data: externalData = null,
  isProfile = false,
}) => {
  const { id } = useParams();
  const [data, setData] = useState(externalData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (externalData) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`${apiEndpoint}/${id}`);
        setData(response.data.data);
        setError("");
      } catch (err) {
        setError("Failed to load data. Please try again.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiEndpoint, id, externalData]);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader size="100px" color="#6439ff" />
      </div>
    );
  if (error) return <p>{error}</p>;

  const renderFieldValue = (field, data) => {
    if (field.renderAsHtml) {
      // Render raw HTML content
      return (
        <div
          className="htmlContent"
          dangerouslySetInnerHTML={{ __html: data[field.key] }}
        ></div>
      );
    }
    if (field.transform && typeof field.transform === "function") {
      return field.transform(data); // Apply transformation
    }
    if (Array.isArray(field.key)) {
      // Handle concatenation for multiple keys
      return field.key.map((key) => data[key]).join(" ");
    }
    return data[field.key]; // Default case
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {hasEdit && (
              <Link
                className="editButton"
                to={isProfile ? editLink + "edit" : editLink + id + "/edit"}
              >
                Edit
              </Link>
            )}
            <h1 className="title">{entity} Details</h1>
            <div className="item">
              {data[avatarKey] && (
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}${data[avatarKey]}`}
                  alt={`${entity}`}
                  className="itemImg"
                />
              )}
              <div className="details">
                {fields.map((field) => (
                  <div className="detailItem" key={field.id}>
                    <span className="itemKey">{field.label}:</span>
                    <span className="itemValue">
                      {renderFieldValue(field, data)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {hasChart && (
            <div className="right">
              <Chart aspect={3 / 1} title={chartTitle} />
            </div>
          )}
        </div>
        <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
