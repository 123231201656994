// NewCategory.js
import New from "../../new/New";
import { userInputs, editUserInputs } from "../../../../formSource";
import axiosInstance from "../../../../utils/axiosInstance";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NewUser = () => {
  const location = useLocation(); // Get the current route
  const isProfileEdit = location.pathname === "/admin/profile/edit"; // Check if the current route is for editing the profile
  const isEditMode = isProfileEdit;
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setFetchError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isEditMode) {
      // Fetch the user data for editing
      const fetchUserData = async () => {
        try {
          setLoading(true);
          const endpoint = `/auth`;
          const response = await axiosInstance.get(endpoint);
          setInitialData(response.data.data); // Assuming user data is under `data`
          setFetchError("");
        } catch (error) {
          console.error("Failed to fetch user for editing:", error);
          setFetchError("Failed to fetch user data");
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    } else {
      setLoading(false); // No fetching required in create mode
    }
  }, [isEditMode, isProfileEdit]);

  const handleUserInputChange = (id, value, updatedValues) => {
    // console.log(
    //   "Input changed:",
    //   id,
    //   value,
    //   "Current form values:",
    //   updatedValues
    // );
  };

  const handleUserSubmit = async (formValues, file) => {
    console.log("FORM VALUES: ", formValues);

    // Validate
    const errors = {};

    // Validate username (must not be empty)
    if (!formValues.username || formValues.username.trim() === "") {
      errors.username = "Username is required";
    }

    // Validate password (must not be empty and at least 6 chars)
    if (!isEditMode) {
      if (!formValues.password || formValues.password.trim().length < 6) {
        errors.password = "Password must be at least 6 characters long";
      }
    }

    // Validate email (basic email format check)
    if (!formValues.email || formValues.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      errors.email = "Invalid email format";
    }

    // Validate firstname (must not be empty)
    if (!formValues.firstname || formValues.firstname.trim() === "") {
      errors.firstname = "First name is required";
    }

    // Validate lastname (must not be empty)
    if (!formValues.lastname || formValues.lastname.trim() === "") {
      errors.lastname = "Last name is required";
    }

    // Validate userType (must be one of admin, normal, instructor)
    const validUserTypes = ["admin", "normal", "instructor"];
    if (!formValues.userType || !validUserTypes.includes(formValues.userType)) {
      errors.userType = "User type must be admin, normal, or instructor";
    }

    // If any errors collected, return them
    if (Object.keys(errors).length > 0) {
      return {
        success: false,
        errors,
      };
    }

    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    try {
      if (isEditMode) {
        console.log("OOOOOOOOO");
        const endpoint = `/user/`;
        await axiosInstance.put(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("User updated successfully");
        navigate("/admin/profile");
      } else {
        await axiosInstance.post("/auth/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("User created successfully");
        navigate("/admin/users");
      }
      return { success: true };
    } catch (error) {
      console.error("Failed to create user:", error);
      // Return errors as needed
      return { success: false, errors: { general: "Failed to create user" } };
    }
  };

  return (
    <New
      inputs={isEditMode ? editUserInputs : userInputs}
      title={isEditMode ? "Edit User" : "Add New User"}
      onInputChange={handleUserInputChange}
      fileKey="avatar"
      onSubmit={handleUserSubmit}
      initialData={initialData}
      loading={loading}
    />
  );
};

export default NewUser;
