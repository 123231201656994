import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../config/data.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

const CourseDetails = (props) => {
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showTarget, setShowTarget] = useState(false);
  const [currentOption, setCurrentOption] = useState("overview");
  const params = useParams();

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/subject/${params.id}`); // Replace with your backend API endpoint
        setCourse(response.data.data); // Ensure your backend returns the correct course data
      } catch (err) {
        console.error("Error fetching course details:", err);
        setError("Failed to load course details. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourseDetails();
  }, [params.id]);

  // useEffect(() => {
  //   const id = params.id;
  //   console.log("id", id);

  //   const tempCourse = data.COURSES.filter((c) => c.id == id);
  //   console.log("temp", tempCourse);

  //   setCourse(tempCourse[0]);
  //   console.log("course", course);
  //   setIsLoading(false);
  // }, [params, course]);

  // <ul><li>The ISO/IEC 27001 Certification Training course prepares you as an IT cybersecurity and privacy protection consultant, providing holistic technical and organizational knowledge as well as experience in appraising a company's technology-related systems and assessing a company's set-up for vulnerabilities.</li></ul>

  // if (isLoading) return null;

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div id="content" className="site-content" bis_skin_checked="1">
        <div className="lp-archive-courses" bis_skin_checked="1">
          <div
            id="learn-press-course"
            className="course-summary"
            bis_skin_checked="1"
          >
            <div
              className="course-content course-summary-content"
              bis_skin_checked="1"
            >
              <div className="course-detail-info" bis_skin_checked="1">
                <div className="lp-content-area" bis_skin_checked="1">
                  <div className="course-info-left" bis_skin_checked="1">
                    <div
                      className="course-meta course-meta-primary"
                      bis_skin_checked="1"
                    >
                      <div
                        className="course-meta__pull-left"
                        bis_skin_checked="1"
                      >
                        {/* <div
                          className="meta-item meta-item-instructor"
                          bis_skin_checked="1"
                        >
                          <div
                            className="meta-item__image"
                            bis_skin_checked="1"
                          >
                            <img
                              alt="User Avatar"
                              src={require(`../assets/imgs/author_5.jpg`)}
                              className="avatar avatar-96 photo"
                              height="250"
                              width="250"
                              loading="lazy"
                            />
                          </div>
                          <div
                            className="meta-item__value"
                            bis_skin_checked="1"
                          >
                            <label>Instructor</label>
                            <div bis_skin_checked="1">
                              <a href="my-profile.html" bis_skin_checked="1">
                                <span>{course.instructor.name}</span>
                              </a>
                            </div>
                          </div>
                        </div> */}
                        <div
                          className="meta-item meta-item-categories"
                          bis_skin_checked="1"
                        >
                          <div
                            className="meta-item__value"
                            bis_skin_checked="1"
                          >
                            <label>Category</label>
                            <div bis_skin_checked="1">
                              <Link
                                to="/programs"
                                rel="tag"
                                bis_skin_checked="1"
                              >
                                {course.category}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1 className="course-title">{course.subjectName}</h1>
                    <div
                      className="course-meta course-meta-secondary"
                      bis_skin_checked="1"
                    >
                      <div
                        className="course-meta__pull-left"
                        bis_skin_checked="1"
                      >
                        <div
                          className="meta-item meta-item-duration"
                          bis_skin_checked="1"
                        >
                          {course.creditHours} Hrs
                        </div>
                        {/* <div
                          className="meta-item meta-item-level"
                          bis_skin_checked="1"
                        >
                          {course.level}
                        </div>
                        <div
                          className="meta-item meta-item-lesson"
                          bis_skin_checked="1"
                        >
                          <span className="meta-number">
                            {course.noOfLessons} lessons
                          </span>
                        </div>
                        <div
                          className="meta-item meta-item-quiz"
                          bis_skin_checked="1"
                        >
                          <span className="meta-number">
                            {course.noOfQuizes} quiz
                          </span>
                        </div>
                        <div
                          className="meta-item meta-item-student"
                          bis_skin_checked="1"
                        >
                          <span className="meta-number">
                            {course.students} students
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="lp-entry-content lp-content-area"
                bis_skin_checked="1"
              >
                <div className="entry-content-left" bis_skin_checked="1">
                  <div className="course-extra-box" bis_skin_checked="1">
                    <h3
                      onClick={() => {
                        setShowTarget((prevState) => !prevState);
                      }}
                      className="course-extra-box__title"
                    >
                      Target audiences
                    </h3>
                    <div
                      className={`course-extra-box__content ${
                        showTarget ? "d-block" : ""
                      }`}
                      bis_skin_checked="1"
                    >
                      <div
                        className="course-extra-box__content-inner ql-editor"
                        bis_skin_checked="1"
                      >
                        {parse(course.targetAudience) || ""}
                        {/* <ul>
                          {course.targetAudience.map((item, idx) => {
                            return (
                              <li key={idx}>
                                {item.main}
                                {item.subs && (
                                  <ul>
                                    {item.subs.map((sub, idx) => {
                                      return <li key={`sub-${idx}`}>{sub}</li>;
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  <div
                    id="learn-press-course-tabs"
                    className="course-tabs"
                    bis_skin_checked="1"
                  >
                    <input
                      type="radio"
                      name="learn-press-course-tab-radio"
                      id="tab-overview-input"
                      checked={currentOption === "overview" ? true : false}
                      value="overview"
                      onChange={() => setCurrentOption("overview")}
                    />
                    <input
                      type="radio"
                      name="learn-press-course-tab-radio"
                      id="tab-organization-benefits-input"
                      checked={
                        currentOption === "organization-benefits" ? true : false
                      }
                      value="organization-benefits"
                      onChange={() => setCurrentOption("organization-benefits")}
                    />
                    <input
                      type="radio"
                      name="learn-press-course-tab-radio"
                      id="tab-career-benefits-input"
                      checked={
                        currentOption === "career-benefits" ? true : false
                      }
                      value="career-benefits"
                      onChange={() => setCurrentOption("career-benefits")}
                    />
                    <input
                      type="radio"
                      name="learn-press-course-tab-radio"
                      id="tab-how-to-acquire-input"
                      checked={
                        currentOption === "how-to-acquire" ? true : false
                      }
                      value="how-to-acquire"
                      onChange={() => setCurrentOption("how-to-acquire")}
                    />
                    <ul
                      className="learn-press-nav-tabs course-nav-tabs"
                      data-tabs="4"
                    >
                      <li
                        className={`course-nav course-nav-tab-overview ${
                          currentOption === "overview" ? "active" : ""
                        }`}
                      >
                        <label htmlFor="tab-overview-input">Overview</label>
                      </li>
                      <li
                        className={`course-nav course-nav-tab-organization-benefits ${
                          currentOption === "organization-benefits"
                            ? "active"
                            : ""
                        }`}
                      >
                        <label htmlFor="tab-organization-benefits-input">
                          Benefits for your Organization
                        </label>
                      </li>
                      <li
                        className={`course-nav course-nav-tab-career-benefits ${
                          currentOption === "career-benefits" ? "active" : ""
                        }`}
                      >
                        <label htmlFor="tab-career-benefits-input">
                          Benefits for your Career
                        </label>
                      </li>
                      <li
                        className={`course-nav course-nav-tab-how-to-acquire ${
                          currentOption === "how-to-acquire" ? "active" : ""
                        }`}
                      >
                        <label htmlFor="tab-how-to-acquire-input">
                          How to Acquire
                        </label>
                      </li>
                    </ul>
                    <div className="course-tab-panels" bis_skin_checked="1">
                      <div
                        className="course-tab-panel-overview course-tab-panel"
                        id="tab-overview"
                        bis_skin_checked="1"
                      >
                        <div
                          className="course-description ql-editor"
                          id="learn-press-course-description"
                          bis_skin_checked="1"
                        >
                          {parse(course.overview) || ""}
                        </div>
                      </div>
                      <div
                        className="course-tab-panel-organization-benefits course-tab-panel"
                        id="tab-organization-benefits"
                        bis_skin_checked="1"
                      >
                        <div
                          className="course-description ql-editor"
                          bis_skin_checked="1"
                        >
                          {parse(course.benefitsForOrganization) || ""}
                        </div>
                      </div>
                      <div
                        className="course-tab-panel-career-benefits course-tab-panel"
                        id="tab-career-benefits"
                        bis_skin_checked="1"
                      >
                        <div
                          className="course-description ql-editor"
                          bis_skin_checked="1"
                        >
                          {parse(
                            course.benefitsForYourCareer === "null" ||
                              !course.benefitsForYourCareer
                              ? ""
                              : course.benefitsForYourCareer
                          )}
                        </div>
                      </div>
                      <div
                        className="course-tab-panel-how-to-acquire course-tab-panel"
                        id="tab-career-how-to-acquire"
                        bis_skin_checked="1"
                      >
                        <div
                          className="course-description ql-editor"
                          bis_skin_checked="1"
                        >
                          {parse(course.howToAcquire) || ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <aside className="course-summary-sidebar">
                  <div
                    className="course-summary-sidebar__inner"
                    bis_skin_checked="1"
                  >
                    <div className="course-sidebar-top" bis_skin_checked="1">
                      <div
                        className="course-sidebar-preview"
                        bis_skin_checked="1"
                      >
                        <div className="media-preview" bis_skin_checked="1">
                          <img
                            width="370"
                            height="280"
                            src={`${process.env.REACT_APP_PUBLIC_URL}${course.thumbnail}`}
                            className="attachment-500x300 size-500x300 wp-post-image"
                            alt="Software Development"
                            loading="lazy"
                            title="Software Development"
                          />
                        </div>
                        {/* <div className="course-price" bis_skin_checked="1">
                          <span className="price">$120.00</span>
                        </div> */}
                        <div className="lp-course-buttons" bis_skin_checked="1">
                          <Link to="/contact-us">
                            <button className="lp-button button button-purchase-course w-100">
                              Ask for quotation
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div
                        className="course-featured-review margin-bottom"
                        bis_skin_checked="1"
                      >
                        <h4 className="featured-review__title"></h4>
                        <div
                          className="featured-review__stars"
                          bis_skin_checked="1"
                        >
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                        <div
                          className="featured-review__content"
                          bis_skin_checked="1"
                        >
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
