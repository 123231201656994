// Datatable.js (After)
import "./datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState } from "react";
import ConfirmationModal from "../confirmation-modal/ConfirmationModal";
import axiosInstance from "../../../utils/axiosInstance";

const Datatable = ({ columns, rows, title, link, deleteApiUrl, viewApi }) => {
  const [data, setData] = useState(rows);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [, setLoading] = useState(false);
  const [error, setError] = useState("");

  const openModal = (id) => {
    console.log("OPEN MODEL: ", id);
    setItemToDelete(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
    setError(""); // Clear previous error messages
  };

  const handleDelete = async () => {
    if (itemToDelete !== null) {
      setLoading(true);
      try {
        const response = await axiosInstance.delete(
          `${deleteApiUrl}/${itemToDelete}`
        );

        // Handle success response message from API
        const successMessage =
          response.data?.message || "Item deleted successfully.";

        setData(data.filter((item) => item.id !== itemToDelete)); // Update data state
        setError(successMessage); // Set success message
        setTimeout(() => closeModal(), 2000); // Close modal after a short delay
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          "Failed to delete the item. Please try again.";
        setError(errorMessage); // Display error message
        console.error(
          "Error deleting item:",
          err.response?.data || err.message
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div className="cellAction">
          <Link
            to={`/admin/${viewApi}/${params.row.id}`}
            style={{ textDecoration: "none" }}
          >
            <div className="viewButton">View</div>
          </Link>
          <div
            className="deleteButton"
            onClick={(e) => {
              e.stopPropagation(); // Prevent propagation to row selection
              openModal(params.row.id); // Open the delete confirmation modal
            }}
          >
            Delete
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New {title}
        <Link to={link} className="link">
          Add New {title}
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDelete}
        message={
          error
            ? error
            : "Are you sure you want to delete this item? This action cannot be undone."
        }
      />
    </div>
  );
};

export default Datatable;
