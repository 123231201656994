import aboutImage from "../../assets/imgs/banners/Facebook_Cover_Flambee_.jpg";
import aboutImage2 from "../../assets/imgs/banners/Facebook_Cover.jpg";

const About = () => {
  return (
    <>
      <div id="about" className="bg-white section_padding">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div
              data-aos="fade-up"
              data-aos-duration="1200"
              className="col-lg-6"
            >
              <h1 className="display-4">About Vertical</h1>
              <p className="lead text-muted">
                An online IT training provider and career advisor, helping
                ambitious individuals uplift their skills to succeed in an
                ever-changing world.
              </p>
              <p className="lead text-muted">
                With a wide international network of experts and professionals,
                Vertical has the unique flexibility to deliver a broad portfolio
                of instructor-led courses online.
              </p>
              <p className="lead text-muted">
                Established in 2021, Vertical contribution within the global
                technology community is more than just IT training. We help you
                build a solid vision and plan for your career path according to
                our research on market needs, and help elevate the skills of
                professionals who seek a higher competitive edge in a
                challenging job marketplace.
              </p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1600"
              className="col-lg-6 d-none d-lg-block"
            >
              <img src={aboutImage} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div id="about-2" className="bg-white section_padding">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div
              data-aos="fade-up"
              data-aos-duration="1600"
              className="col-lg-6 d-none d-lg-block"
            >
              <img src={aboutImage2} alt="" className="img-fluid" />
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1200"
              className="col-lg-6"
            >
              <h1 className="display-4">Why Vertical</h1>
              <p className="lead text-muted">
                Vertical is partnering with an elite group of instructors with
                real-life experience working in multinational companies, banks,
                and well reputable global and regional organizations, who will
                not only deliver the body of knowledge at an excellent level but
                also will provide you with real hands-on experience that will
                help you acquire your next career step with top employers.
              </p>
              <p className="lead text-muted">
                Vertical after-customer service assures follow-up with you till
                you can pass the exam and acquire your certificates; our
                relationship does not end by the course completion rather we
                consider ourselves as your partners to achieve your career goals
                over the years.
              </p>
              <p className="lead text-muted">
                Vertical is extremely competitive and at least 40% less than
                market price.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="about-3" className="bg-white section_padding">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div
              data-aos="fade-up"
              data-aos-duration="1200"
              className="col-lg-6"
            >
              <h1 className="display-4">Why Vertical</h1>
              <p className="lead text-muted">
                We are well experienced in customizing training solutions
                tailored for your organization and accommodating your business
                needs and work environment.
              </p>
              <p className="lead text-muted">
                We are partnering with an elite group of instructors with
                multinational, well reputable global and regional organization
                experience.
              </p>
              <p className="lead text-muted">
                Our Instructors will not only deliver the body of knowledge at
                an excellent level and assure required certification acquirement
                but also will provide your employees with hands-on experience
                that will help them to utilize their newly gained knowledge
                boosting your organisation's performance and competitiveness.
              </p>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1600"
              className="col-lg-6 d-none d-lg-block"
            >
              <img src={aboutImage2} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
