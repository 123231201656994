import React from "react";
import "./confirmationModal.css";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h3>Confirm Deletion</h3>
        <p>{message || "Are you sure you want to delete this item?"}</p>
        <div className="modalActions">
          <button className="cancelButton" onClick={onClose}>
            Cancel
          </button>
          <button className="confirmButton" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
