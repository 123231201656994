// NewCategory.js
import New from "../../new/New"; // Adjust path as needed
import { categoryInputs } from "../../../../formSource"; // Adjust path if needed
import axiosInstance from "../../../../utils/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const NewSubjectCategory = () => {
  const { id } = useParams(); // Get `id` from URL
  const navigate = useNavigate();
  const isEditMode = !!id; // Determine if it's edit mode
  const [initialData, setInitialData] = useState(null); // To store fetched data for edit mode
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      // Fetch the category data for editing
      const fetchCategory = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get(`/subjectcategory/${id}`);
          setInitialData(response.data.data); // Assuming your API response includes `data`
        } catch (error) {
          console.error("Failed to fetch category for editing:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCategory();
    }
  }, [id, isEditMode]);

  const handleCategoryInputChange = (id, value, updatedValues) => {
    // console.log(
    //   "Input changed:",
    //   id,
    //   value,
    //   "Current form values:",
    //   updatedValues
    // );
  };

  const handleCategorySubmit = async (formValues, file) => {
    // Validate
    if (!formValues.name || formValues.name.trim() === "") {
      return {
        success: false,
        errors: { name: "Category name is required" },
      };
    }

    try {
      if (isEditMode) {
        await axiosInstance.put(`/subjectcategory/${id}`, formValues);
        console.log("Category updated successfully");
        navigate("/admin/subject-categories");
      } else {
        await axiosInstance.post("/subjectcategory", formValues);
        console.log("Category created successfully");
      }
      return { success: true };
    } catch (error) {
      console.error("Failed to create subject category:", error);
      // You could return errors from server if available, or a generic error
      return {
        success: false,
        errors: {
          name:
            error.response?.data?.message || "Submission failed. Try again.",
        },
      };
    }
  };

  return (
    <New
      inputs={categoryInputs}
      title={isEditMode ? "Edit Category" : "Add New Category"}
      showImageUpload={false}
      onInputChange={handleCategoryInputChange}
      onSubmit={handleCategorySubmit}
      initialData={initialData} // Pass initial data for edit mode
      loading={loading}
    />
  );
};

export default NewSubjectCategory;
