import { userColumns } from "../../../../datatablesource";
import List from "../../list/List";
import { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axiosInstance"; // Adjust path if needed
import Loader from "../../../../components/layout/loader/Loader";

const UsersList = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch users from /api/users
    axiosInstance
      .get("/user")
      .then((response) => {
        // Assuming response.data is an array of users
        console.log("RESPONSE: ", response.data);
        setRows(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch users:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader size="100px" color="#6439ff" />
      </div>
    );
  }

  return (
    <List
      columns={userColumns}
      rows={rows}
      title="User"
      addLink={`/admin/users/new`}
      deleteApiUrl="/user"
      viewApi="users"
    />
  );
};

export default UsersList;
