export const userDetailFields = [
  { id: "email", key: "email", label: "Email" },
  { id: "username", key: "username", label: "Username" },
  {
    id: "fullName",
    key: ["firstname", "lastname"], // Keys to concatenate
    label: "Full Name",
    transform: (data) => `${data.firstname} ${data.lastname}`, // Transformation function
  },
  {
    id: "userType",
    key: "userType",
    label: "Role",
    transform: (data) =>
      data.userType.charAt(0).toUpperCase() + data.userType.slice(1), // Capitalize first letter
  },
];

export const profileDetailFields = [
  { id: "email", key: "email", label: "Email" },
  { id: "username", key: "username", label: "Username" },
  {
    id: "firstname",
    key: "firstname",
    label: "First Name",
  },
  {
    id: "lastname",
    key: "lastname",
    label: "Last Name",
  },
  {
    id: "userType",
    key: "userType",
    label: "Role",
    transform: (data) =>
      data.userType.charAt(0).toUpperCase() + data.userType.slice(1), // Capitalize first letter
  },
];

export const subjectCategoryDetailFields = [
  { id: "id", key: "id", label: "ID" },
  { id: "name", key: "name", label: "Name" },
];

export const subjectDetailFields = [
  { id: "subjectName", key: "subjectName", label: "Subject Name" },
  { id: "creditHours", key: "creditHours", label: "Credit Hours" },
  { id: "category", key: "category", label: "Category" },
  {
    id: "overview",
    key: "overview",
    label: "Overview",
    renderAsHtml: true, // Add flag for fields with HTML
  },
  {
    id: "targetAudience",
    key: "targetAudience",
    label: "Target Audience",
    renderAsHtml: true,
  },
  {
    id: "benefitsForOrganization",
    key: "benefitsForOrganization",
    label: "Benefits for Organization",
    renderAsHtml: true,
  },
  {
    id: "benefitsForYourCareer",
    key: "benefitsForYourCareer",
    label: "Benefits for Your Career",
    renderAsHtml: true,
  },
  {
    id: "howToAcquire",
    key: "howToAcquire",
    label: "How to Acquire",
    renderAsHtml: true,
  },
];
