import "./assets/styles/style.css";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import router from "./router";
import "react-quill/dist/quill.snow.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: function () {
        var maxWidth = 768;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
