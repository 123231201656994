// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const [loading, setLoading] = useState(true); // Loading state during initial auth check
  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   // Check for token in localStorage on initial load
  //   const token = localStorage.getItem("adminToken");
  //   console.log("AuthContext: Retrieved token from localStorage:", token);
  //   if (token) {
  //     setIsAuthenticated(true);
  //     console.log("AuthContext: User is authenticated.");
  //   } else {
  //     console.log("AuthContext: User is not authenticated.");
  //   }
  //   setLoading(false); // Authentication check complete
  // }, [setIsAuthenticated, setLoading]);

  const isTokenExpired = () => {
    const expiresAt = localStorage.getItem("expires_at");
    if (!expiresAt) return true; // If no expiration date, assume expired
    return Date.now() > parseInt(expiresAt, 10); // Compare with current time
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem("adminToken");
      if (token && !isTokenExpired()) {
        try {
          const response = await axiosInstance.get("/auth", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data.data);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Failed to fetch user:", error);
          logout(); // Clear invalid/expired token
        }
      } else {
        logout();
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  // const login = (token) => {
  //   console.log("AuthContext: Logging in with token:", token);
  //   localStorage.setItem("adminToken", token);
  //   setIsAuthenticated(true);
  // };

  const login = async (token, expiresAt) => {
    localStorage.setItem("adminToken", token);
    localStorage.setItem("expires_at", expiresAt);
    setIsAuthenticated(true);
    try {
      const response = await axiosInstance.get("/api/auth", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data.data); // Fetch user after login
    } catch (error) {
      console.error("Failed to fetch user after login:", error);
    }
  };

  // const logout = () => {
  //   console.log("AuthContext: Logging out.");
  //   localStorage.removeItem("adminToken");
  //   setIsAuthenticated(false);
  // };

  const logout = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("expires_at");
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
