// src/pages/admin/AdminLogin.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";
import { AuthContext } from "../../../context/AdminAuthContext";
import {
  Avatar,
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Alert,
  TextField,
  CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      console.log(
        "AdminLogin: isAuthenticated is true, navigating to dashboard."
      );
      navigate("/admin/dashboard", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await axiosInstance.post("/auth", {
        username,
        password,
      });
      console.log("AdminLogin: Response data:", response.data);
      if (response.data.success) {
        const { token, expiresAt } = response.data.data; // Extract token and expiresAt
        login(token, expiresAt); // Update authentication state
        // Navigation will be handled by useEffect
      } else {
        setError(response.data.message || "Login failed");
      }
    } catch (err) {
      console.error("AdminLogin: Login error:", err);
      setError(
        err.response?.data?.message || "An error occurred during login."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container maxWidth="xs">
      <Paper elevation={10} sx={{ marginTop: 8, padding: 2 }}>
        <Avatar
          sx={{
            mx: "auto",
            bgcolor: "#5328d6",
            textAlign: "center",
            mb: 1,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
          Sign In
        </Typography>

        {/* Error Message */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            placeholder="Enter username"
            fullWidth
            required
            autoFocus
            value={username} // Bind value
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "40px", // Adjust the height here
                "& input": {
                  padding: "16px 14px", // Adjust padding for better text alignment
                },
              },
            }}
            size="medium"
          />
          <TextField
            placeholder="Enter password"
            fullWidth
            required
            type="password"
            value={password} // Bind value
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px", // Adjust the height
                "& input": {
                  padding: "16px 14px", // Adjust padding
                },
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              mt: 3,
              backgroundColor: "#6439ff", // Set the button color
              "&:hover": {
                backgroundColor: "#5328d6", // Optional: Slightly darker for hover
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};
export default AdminLogin;
