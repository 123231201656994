// src/pages/admin/new/New.jsx

import "./new.css";
import Sidebar from "../../../components/admin/sidebar/Sidebar";
import Navbar from "../../../components/admin/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect, useMemo } from "react";

import ReactQuill from "react-quill";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import "react-quill/dist/quill.snow.css";

const New = ({
  inputs,
  title,
  showImageUpload = true,
  fileKey = "file",
  onInputChange,
  onSubmit,
  initialData = null,
}) => {
  const [file, setFile] = useState("");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  // const [resetTrigger, setResetTrigger] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formKey, setFormKey] = useState(Date.now()); // For tab handling:
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Initialize form values for create or edit mode
    const initialValues = {};
    inputs.forEach((input) => {
      initialValues[input.id] = input.type === "file" ? null : "";
    });
    if (initialData) {
      Object.assign(initialValues, initialData); // Merge initial data for edit mode
    }

    setFormValues(initialValues);
  }, [inputs, initialData]);

  const handleInputChange = (id, value) => {
    const updatedValues = { ...formValues, [id]: value };
    setFormValues(updatedValues);
    if (onInputChange) {
      onInputChange(id, value, updatedValues);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({}); // Clear previous errors
    setLoadingSubmit(true);

    // Prepare data to submit
    const submissionData = { ...formValues };
    if (showImageUpload && file) {
      submissionData[fileKey] = file;
    }

    const result = await onSubmit(submissionData, file);
    setLoadingSubmit(false);
    if (result.success) {
      console.log("Form submitted successfully!");
      // Trigger form reset
      toast.success("Form submitted successfully!");
      // setResetTrigger(true);
      setFormValues({});
      setFile("");
      setFormKey(Date.now());
    } else {
      if (result.errors) {
        console.log("ERRORS: ", result.errors);
        setFormErrors(result.errors);

        if (result.errors.general) {
          toast.error(result.errors.general);
        } else {
          toast.error("An error occurred. Please try again.");
        }
      }
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    }),
    []
  );

  const formats = useMemo(
    () => [
      "header",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "indent",
      "align",
      "color",
      "background",
    ],
    []
  );

  const editorInputs = inputs.filter((input) => input.type === "editor");
  const hasEditorInputs = editorInputs.length > 0;

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className="titlw">{title}</h1>
        </div>
        <div className="bottom">
          {showImageUpload && (
            <div className="left">
              <img
                src={
                  file
                    ? URL.createObjectURL(file) // If a new file is selected
                    : formValues[fileKey] // Use the initial data for edit mode
                    ? `${process.env.REACT_APP_PUBLIC_URL}${formValues[fileKey]}` // Construct URL for existing file
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg" // Default placeholder
                }
                alt="Uploaded"
                onError={(e) => {
                  e.target.src =
                    "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"; // Fallback image
                }}
              />
            </div>
          )}
          <div className="right">
            <form key={formKey} onSubmit={handleSubmit}>
              {showImageUpload && (
                <div className="formInput">
                  <label htmlFor="file">
                    {fileKey.charAt(0).toLocaleUpperCase() + fileKey.slice(1)}:
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </div>
              )}

              {inputs.map((input) => {
                if (input.type === "select") {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <select
                        value={formValues[input.id] || ""}
                        onChange={(e) =>
                          handleInputChange(input.id, e.target.value)
                        }
                      >
                        <option value="">Select...</option>
                        {input.options &&
                          input.options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                      {formErrors[input.id] && (
                        <span className="errorMsg">{formErrors[input.id]}</span>
                      )}
                    </div>
                  );
                } else if (input.type === "textarea") {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <textarea
                        placeholder={input.placeholder || ""}
                        value={formValues[input.id] || ""}
                        style={{ padding: "16px" }}
                        onChange={(e) =>
                          handleInputChange(input.id, e.target.value)
                        }
                        rows={input.rows || 30} // Optional: Default to 4 rows if not specified
                        cols={input.cols || 25} // Optional: Default to 50 cols if not specified
                      ></textarea>
                      {formErrors[input.id] && (
                        <span className="errorMsg">{formErrors[input.id]}</span>
                      )}
                    </div>
                  );
                } else if (input.type === "checkbox") {
                  return (
                    <div
                      className="formInput checkboxInput"
                      key={input.id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label className="checkboxLabel">
                        {formValues[input.id]}
                        <input
                          type="checkbox"
                          checked={!!formValues[input.id]} // Cast value to boolean
                          onChange={(e) =>
                            handleInputChange(input.id, e.target.checked)
                          }
                        />
                        <span className="customCheckbox"></span>
                        {input.label}
                      </label>
                    </div>
                  );
                } else if (input.type === "file") {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        type="file"
                        onChange={(e) => {
                          const fileInput = e.target.files[0];
                          handleInputChange(input.id, fileInput || "");
                        }}
                      />
                      {formErrors[input.id] && (
                        <span className="errorMsg">{formErrors[input.id]}</span>
                      )}
                    </div>
                  );
                } else if (input.type === "text" || input.type === "number") {
                  // Default text input
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        type={input.type}
                        placeholder={input.placeholder}
                        value={formValues[input.id] || ""}
                        onChange={(e) =>
                          handleInputChange(input.id, e.target.value)
                        }
                      />
                      {formErrors[input.id] && (
                        <span className="errorMsg">{formErrors[input.id]}</span>
                      )}
                    </div>
                  );
                }
              })}

              {/* Render TAB HEADER and CONTENT only if there are editor fields */}
              {hasEditorInputs && (
                <>
                  {/* TAB HEADER */}
                  <div className="editorTabs">
                    {editorInputs.map((tab, index) => (
                      <button
                        key={tab.id}
                        type="button"
                        className={`tabButton ${
                          activeTab === index ? "activeTab" : ""
                        }`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                      </button>
                    ))}
                  </div>

                  {/* TAB CONTENT */}
                  <div className="tabContent">
                    {editorInputs.map((tab, index) => {
                      if (activeTab !== index) return null; // Only render active tab
                      return (
                        <div key={tab.id} className="editorWrapper">
                          <ReactQuill
                            theme="snow"
                            value={formValues[tab.id] || ""}
                            onChange={(value) =>
                              handleInputChange(tab.id, value)
                            }
                            modules={modules}
                            formats={formats}
                          />
                          {formErrors[tab.id] && (
                            <span className="errorMsg">
                              {formErrors[tab.id]}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <button type="submit">
                {loadingSubmit ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
