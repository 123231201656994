// src/pages/admin/users/UserDetail.jsx
import React from "react";
import Single from "../../single/Single";
import { subjectCategoryDetailFields } from "../../../../datadetails";

const SubejctCategoryDetails = () => {
  return (
    <Single
      entity="Subject Category"
      apiEndpoint="/subjectcategory"
      chartTitle="User Spending (Last 6 Months)"
      fields={subjectCategoryDetailFields}
      hasChart={false}
      editLink="/admin/subject-categories/"
    />
  );
};

export default SubejctCategoryDetails;
