import axios from "axios";
import API_URL from "../config";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("adminToken");
    const expiresAt = localStorage.getItem("expires_at");

    if (token && expiresAt && Date.now() > parseInt(expiresAt, 10)) {
      localStorage.removeItem("adminToken");
      localStorage.removeItem("expires_at");
      window.location.reload(); // Force logout and reload
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized, possibly token expired
      // Perform logout or redirect to login
      localStorage.removeItem("adminToken");
      localStorage.removeItem("expires_at");
      window.location.href = "/admin/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
