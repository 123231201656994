// src/pages/admin/profile/ProfileDetails.jsx
import Single from "../single/Single";
import { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import Loader from "../../../components/layout/loader/Loader";
import { profileDetailFields } from "../../../datadetails";

const ProfileDetails = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axiosInstance.get("/auth");
        console.log("DATA: ", response);
        setProfileData(response.data.data);
      } catch (err) {
        setError("Failed to load profile data. Please try again.");
        console.error("Error fetching profile data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader size="100px" color="#6439ff" />
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Single
          entity="Profile"
          fields={profileDetailFields}
          data={profileData}
          hasChart={false}
          hasAvatar={true}
          isProfile={true}
          editLink="/admin/profile/"
        />
      )}
    </>
  );
};

export default ProfileDetails;
