import userProfileFallback from "./assets/imgs/user_profile.png";

export const userColumns = [
  { field: "id", headerName: "ID", width: 230 },
  {
    field: "user",
    headerName: "User",
    width: 100,
    renderCell: (params) => {
      let imgSrc;
      let imgClassName = "cellImg";

      if (params.row.avatar) {
        imgSrc = `${process.env.REACT_APP_PUBLIC_URL}${params.row.avatar}`;
      } else {
        imgSrc = userProfileFallback;
        imgClassName += " fallback"; // Add a fallback class when no avatar
      }

      return (
        <div className="cellWithImg">
          <img
            className={imgClassName}
            src={imgSrc}
            alt={
              params.row.username
                ? `${params.row.username}'s avatar`
                : "User avatar"
            }
          />
        </div>
      );
    },
  },
  {
    field: "username",
    headerName: "Username",
    width: 100,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },
  {
    field: "userType",
    headerName: "Role",
    width: 100,
  },
];

export const subjectCategoriesColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "name",
    headerName: "Subject Category",
    width: 230,
  },
];

export const subjectColumns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "subject",
    headerName: "Thumbnail",
    width: 100,
    renderCell: (params) => {
      let imgSrc;
      let imgClassName = "cellImg";

      if (params.row.thumbnail) {
        imgSrc = `${process.env.REACT_APP_PUBLIC_URL}${params.row.thumbnail}`;
      } else {
        imgSrc = userProfileFallback;
        imgClassName += " fallback"; // Add a fallback class when no avatar
      }

      return (
        <div className="cellWithImg">
          <img
            className={imgClassName}
            src={imgSrc}
            alt={
              params.row.subjectName
                ? `${params.row.subjectName}'s thumbnail`
                : "Subject Thumbnail"
            }
          />
        </div>
      );
    },
  },
  {
    field: "subjectName",
    headerName: "Subject",
    width: 150,
  },
  {
    field: "creditHours",
    headerName: "Credit Hours",
    width: 40,
  },
  {
    field: "category",
    headerName: "Category",
    width: 180,
  },
];
