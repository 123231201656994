import "./list.css";
import Sidebar from "../../../components/admin/sidebar/Sidebar";
import Navbar from "../../../components/admin/navbar/Navbar";
import Datatable from "../../../components/admin/datatable/Datatable";

const List = ({ columns, rows, title, addLink, deleteApiUrl, viewApi }) => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {/* Pass columns and rows down to Datatable */}
        <Datatable
          columns={columns}
          rows={rows}
          title={title}
          link={addLink}
          deleteApiUrl={deleteApiUrl}
          viewApi={viewApi}
        />
      </div>
    </div>
  );
};

export default List;
