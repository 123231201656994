import React from "react";
import "./loader.css";

const Loader = ({ size = "50px", color = "#6439ff" }) => {
  return (
    <div
      className="loader"
      style={{
        width: size,
        height: size,
        borderColor: `${color} transparent ${color} transparent`,
      }}
    ></div>
  );
};

export default Loader;
