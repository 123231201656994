// src/pages/admin/users/UserDetail.jsx
import React from "react";
import Single from "../../single/Single";
import { subjectDetailFields } from "../../../../datadetails";

const SubejctDetails = () => {
  return (
    <Single
      entity="Subject"
      apiEndpoint="/subject"
      chartTitle="User Spending (Last 6 Months)"
      fields={subjectDetailFields}
      hasChart={false}
      avatarKey="thumbnail"
      editLink="/admin/subjects/"
    />
  );
};

export default SubejctDetails;
