import { Link } from "react-router-dom";

const CourseCard = (props) => {
  const { course } = props;

  const setCategory = (category) => {
    props.onSetCategory(category);
  };

  return (
    <li
      id="post-1026"
      className="post-1026 lp_course type-lp_course status-publish has-post-thumbnail hentry course_category-computer-science course"
    >
      <div className="course-item" bis_skin_checked="1">
        <div className="course-wrap-thumbnail" bis_skin_checked="1">
          <div className="course-thumbnail" bis_skin_checked="1">
            <Link to={`/courses/${course.id}`} bis_skin_checked="1">
              <div className="thumbnail-preview" bis_skin_checked="1">
                <div className="thumbnail" bis_skin_checked="1">
                  <div className="centered" bis_skin_checked="1">
                    <img
                      width="370"
                      height="280"
                      src={`${process.env.REACT_APP_PUBLIC_URL}${course.thumbnail}`}
                      className="attachment-500x300 size-500x300 wp-post-image"
                      alt={course.subjectName}
                      loading="lazy"
                      title={course.subjectName}
                    />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="course-content" bis_skin_checked="1">
          <div className="course-categories" bis_skin_checked="1">
            <a
              style={{ cursor: "pointer" }}
              onClick={setCategory.bind(this, course.category)}
              bis_skin_checked="1"
            >
              {course.category}
            </a>
          </div>
          {/* <span className="course-instructor">
            <a href="my-profile.html" bis_skin_checked="1">
              <span>{course.instructor.name}</span>
            </a>
          </span> */}
          <Link className="course-permalink" to={`/courses/${course.id}`}>
            <h3 className="course-title">{course.subjectName}</h3>
          </Link>
          <div className="course-wrap-meta" bis_skin_checked="1">
            <div className="meta-item meta-item-duration" bis_skin_checked="1">
              {course.creditHours} Hrs
            </div>
            {/* <div className="meta-item meta-item-level" bis_skin_checked="1">
              {course.level}
            </div>
            <div className="meta-item meta-item-lesson" bis_skin_checked="1">
              <span className="meta-number">{course.noOfLessons} lessons</span>
            </div>
            <div className="meta-item meta-item-quiz" bis_skin_checked="1">
              <span className="meta-number">{course.noOfQuizes} quiz</span>
            </div>
            <div className="meta-item meta-item-student" bis_skin_checked="1">
              <span className="meta-number">{course.students} students</span>
            </div> */}
          </div>
          <div className="separator" bis_skin_checked="1"></div>
          <div className="course-info" bis_skin_checked="1">
            <div className="course-excerpt" bis_skin_checked="1">
              {course.description}
            </div>
            <div className="clearfix" bis_skin_checked="1"></div>
            {/* <div className="course-footer" bis_skin_checked="1">
              <div className="course-price" bis_skin_checked="1">
                <span className="price">${course.price}</span>
              </div>
            </div> */}
            <div className="course-readmore" bis_skin_checked="1">
              <Link to={`/courses/${course.id}`} bis_skin_checked="1">
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CourseCard;
