export const userInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "firstname",
    label: "First Name",
    type: "text",
    placeholder: "John",
  },
  {
    id: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "userType",
    label: "User Type",
    type: "select",
    options: [
      { value: "admin", label: "Admin" },
      { value: "normal", label: "Normal" },
      { value: "instructor", label: "Instructor" },
    ],
  },
];

export const editUserInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "firstname",
    label: "First Name",
    type: "text",
    placeholder: "John",
  },
  {
    id: "lastname",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "userType",
    label: "User Type",
    type: "select",
    options: [
      { value: "admin", label: "Admin" },
      { value: "normal", label: "Normal" },
      { value: "instructor", label: "Instructor" },
    ],
  },
];

export const categoryInputs = [
  {
    id: "name",
    label: "Category Name",
    type: "text",
    placeholder: "Enter subject category name",
  },
];

export const subjectInputs = [
  {
    id: "subjectName",
    label: "Subject Name",
    type: "text",
    placeholder: "Enter subject name",
  },
  {
    id: "description",
    label: "Description",
    type: "textarea",
    placeholder: "Enter description",
  },
  {
    id: "overview",
    label: "Overview",
    type: "editor",
    placeholder: "Up to 2000 chars",
  },
  {
    id: "targetAudience",
    label: "Target Audience",
    type: "editor",
    placeholder: "Describe target audience",
  },
  {
    id: "benefitsForOrganization",
    label: "Benefits For Organization",
    type: "editor",
    placeholder: "Describe benefits",
  },
  {
    id: "benefitsForYourCareer",
    label: "Benefits For Your Career",
    type: "editor",
    placeholder: "Describe career benefits",
  },
  {
    id: "howToAcquire",
    label: "How To Acquire",
    type: "editor",
    placeholder: "Explain how to acquire",
  },
  {
    id: "creditHours",
    label: "Credit Hours",
    type: "number",
    placeholder: "e.g. 3",
  },
  {
    id: "categoryId",
    label: "Category",
    type: "select",
  },
  {
    id: "isPopular",
    label: "Is Popular",
    type: "checkbox", // Add a new checkbox field
  },
];
